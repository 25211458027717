// 被searchForm包裹
export var mixins = {
    data: () => {
        return {
            obj: { //新增量表参数缓存
                topic: {
                    topicId: "",
                    topicName: "",
                    iconUrl: "",
                    topicDesc: "",
                    subDesc: "",
                    isPublic: 0,
                    topicType: "",
                    psyType: 4,
                    psyTypeName: "",
                    status: 0,
                    remark: "",
                    topicChart: "",
                    factorScheme: "",
                    factorSchemeClass: {},
                    adaptorType: ""
                },
                subjects: [], //量表题目
                test: {
                    // formulaName: "", //公式名称
                    // roundType: 0, //取整算法 （ 0向下取整 1向上取整 2四舍五入 3不取整保留2位小数）
                    // formulaExpr: "", //算法公式
                    // showSubFactors: "", //显示 二级因子配置
                    // showFilters: "", //显示 效度量表配置
                    // subFactors: "", //二级因子配置
                    // filters: "", //效度量表配置
                },
                // sum: 0, //每题总分
                // fullMarks: 0, //满分
                // schemes: [], //测评结果列表
            },
        }
    },
    methods: {

    },
}