<template>
  <div class="gauge">
    <div class="title">
      <span>量表管理 /</span> <span class="black">量表管理</span>
    </div>
    <el-card class="condition_style2">
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" size="small" @click="add('true')">添加量表</el-button>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchForm.topicName" class="w_170" placeholder="请输入量表名称" suffix-icon="el-icon-search"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item prop="adaptorType">
          <el-select class="w_100" v-model="searchForm.adaptorType" placeholder="请选择所属板块" clearable>
            <el-option v-for="(item, index) in blackList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" style="width: 100%" class="table">
        <el-table-column prop="mainPhoto" label="图标" align="center">
          <template slot-scope="scope">
            <div class="flex_just_center ">
              <img class="img_40" :src="scope.row.iconUrl" v-if="scope.row.iconUrl">
              <div v-else class="icon_div">{{ scope.row.topicName&&scope.row.topicName.substring(0, 2) }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="topicName" label="测评量表" min-width="200" align="center"></el-table-column>
        <el-table-column prop="adaptorType" label="所属板块" min-width="120" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.adaptorType==3?"学生":scope.row.adaptorType==4?"家长":""}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="topicTypeName" label="类别" min-width="120" align="center"></el-table-column>
        <el-table-column prop="questionCount" label="题目数" min-width="100" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="add(scope.row.topicId)">编辑
            </el-button>
            <el-button type="text" size="small" @click="see(scope.row)">查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="searchForm.page" :size="searchForm.limit" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>
    <!-- 查看 -->
    <modal :obj="{
        title: topicName,
        dialog: closeDialog,
        dialogText: 'closeDialog',
        width: '800px',
        hidOk: true,
        hidCancel: true,
      }">
      <!-- subjectType 1单选  2多选  3填空 4时间_点_分 5时长_小时_分钟 -->
      <el-form disabled>
        <div v-for="(item, i) in subjectVos" :key="i">
          <div v-if="item.subjectElsename" class="b_title">
            {{ item.subjectElsename }}
          </div>
          <div class="sub_title">
            {{i+1}}、{{ item.questionDesc }}<span class="sub_span">{{ item.extraText }}</span>
          </div>
          <el-form-item class="sub_item">
            <!-- 单选 -->
            <el-radio-group v-model="item.compId" v-if="item.questionSubType == 1">
              <el-radio v-for="(val, i) in item.topicOptions" :key="i">{{ val.optionDesc
                }}<span class="sub_span">{{ val.optionScore }}</span>
              </el-radio>
            </el-radio-group>
            <!-- 多选 -->
            <el-checkbox-group v-model="item.compId" v-if="item.questionSubType == 2">
              <el-checkbox name="type" v-for="(val, i) in item.topicOptions" :key="i">{{ val.optionDesc }}<span
                  class="sub_span">{{ val.optionScore }}</span></el-checkbox>
            </el-checkbox-group>
            <!-- 填空   -->
            <el-input v-if="item.questionSubType == 3" placeholder="请输入内容"></el-input>
            <!-- 时间、时长 -->
            <el-date-picker v-if="item.questionSubType == 4||item.questionSubType == 5" type="date" format="yyyy-MM-dd"
              value-format="yyyy-MM-dd" placeholder="请选择时间">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "@/components/pagination";
  import modal from "@/components/modal";
  import {
    topicList,
    gaugeQuestionsList
  } from "@/api/api";
  import {
    mixins
  } from "./mixins"
  export default {
    components: {
      pagination,
      modal,
    },
    mixins: [mixins],
    data() {
      return {
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0, //总数
        searchForm: {
          topicName: "",
          typeTheme: 4,
          adaptorType: "",

        },

        closeDialog: false,
        topicName: "",
        tableData: [],
        subjectVos: [],
        blackList: [{
          id: 3,
          name: '学生'
        }, {
          id: 4,
          name: '家长'
        }],
      };
    },
    computed: {
      poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList ? this.$store.state.poewrMenuIdList : []
      },
    },
    created() {
      if (this.$route.params.form) {
        this.searchForm = JSON.parse(this.$route.params.form)
      }
      this.getList();
    },
    methods: {
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.$request(topicList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
      //   新增\编辑 
      add(val) {
        this.$store.commit("setParam", {
          gaugeAddForm: this.obj
        });
        this.$router.push({
          name: "gaugeAdd",
          params: {
            id: val,
            form: JSON.stringify(this.searchForm)
          }
        });
      },
      // 查看
      see(val) {
        this.subjectVos = []
        this.topicName = val.topicName;
        this.$request(gaugeQuestionsList, {
          topicId: val.topicId
        }).then((res) => {
          if (res.code === 0) {
            this.subjectVos = res.data;
          }
        });
        this.closeDialog = true;
      },
    },
  };
</script>

<style lang="less" scoped>
  .gauge {
    .w_170 {
      width: 170px;

      /deep/ .el-input__suffix {
        top: -3px;
      }

      /deep/ .el-input__icon {
        line-height: 50px;
      }
    }

    .el-radio {
      display: block;
      margin: 10px 0;
    }

    .el-checkbox {
      display: block;
      line-height: 30px;
    }

    .sub_item {
      margin-top: 8px;
    }

    .b_title {
      font-size: 18px;
      color: #409eff;
    }

    .sub_title,
    /deep/.el-radio__input.is-disabled+span.el-radio__label,
    /deep/.el-checkbox__input.is-disabled+span.el-checkbox__label {
      color: #000000d9;
    }

    .sub_span {
      color: #00000040;
      margin-left: 8px;
    }

    /deep/.el-table {
      overflow: visible;

      .el-table__body-wrapper {
        overflow: visible;
      }

      td {
        padding: 4px 0px;

        .cell {
          overflow: visible;
        }
      }
    }

    .img_40 {
      width: 40px;
      height: 40px;
      transition: transform 0.6s;
      z-index: 900;

      &:hover {
        transform: scale(2.25);
        z-index: 999;
      }
    }

    .icon_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      color: white;
      background-color: rgb(64, 169, 255);
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
  }
</style>